#commandbar__control{
    padding: 11px !important;
    padding-left: 15px !important;
    
    
}
#commandbar__control_wrapper{
    box-shadow: none !important;
}
.commandbar-bar-ohxty5 > div > div{
    box-shadow: none !important;
}
.MuiButton-root{
    min-width: 40px
}