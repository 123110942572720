input::file-selector-button {
    font-weight: bold;
    color: dodgerblue;
    padding: 0.2em;
    border: thin solid grey;
    border-radius: 3px;
    cursor: pointer;
    background-color: white;
}

input::file-selector-button:hover {
    background-color: rgba(128, 128, 128, 0.068);
}
input::file-selector-button:active {
    transform: scale(0.98);
}
