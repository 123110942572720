:root{
    --calendar_dark_primary:rgba(66, 165, 245, 1);
    --calendar_light_primary:rgba(66, 165, 245, 0.5);
    --calendar_lighter_primary:rgba(66, 165, 245, 0.3);
}
.DateRangePicker{
  /* margin: auto; */
}
.CalendarDay__selected_span {
  background: var(--calendar_lighter_primary);
  color: black;
  border: 1px solid var(--calendar_light_primary); 
}
.CalendarDay__selected_span:hover{
    background-color:var(--calendar_light_primary);
    color: black;
}
.CalendarDay__selected {
  background: var(--calendar_dark_primary);
  color: white;
}

.CalendarDay__selected:hover {
  background: var(--calendar_dark_primary);
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--calendar_lighter_primary);
}

.DateRangePickerInput{
  border: 1px solid initial;
  border-radius: 6px;
  overflow: clip;
  background: none;
}

.DateInput_input{
  color: #212B36;
  font-weight:normal;
  background: none;
}
.DateInput{
  background:none;
}